import { useActor } from '@xstate/react';
import { z } from 'zod';
import { CredentialsForm } from '@client/components/credentials-form';
import { VerifyingCredentials } from '@client/components/verifying-credentials';
import { MfaVerificationMethodForm } from '@client/components/mfa-verification-method-form';
import { MfaVerificationCodeForm } from '@client/components/mfa-verification-code-form';
import { CredentialsMachineActor } from './types';

export type Props = {
  credentialsMachineRef: CredentialsMachineActor;
};

export const CredentialsScreens = ({ credentialsMachineRef }: Props): JSX.Element | null => {
  const [credentialsState, send] = useActor(credentialsMachineRef);
  const { context: credentialsContext } = credentialsState;
  const delays = credentialsState.machine?.options.delays;

  if (credentialsState.matches('credentialsForm'))
    return (
      <CredentialsForm
        organization={credentialsContext.organization}
        templateFields={credentialsContext.templateFields}
        acquisitionTemplate={credentialsContext.acquisitionTemplate}
        provider={credentialsContext.provider}
        pending={credentialsState.matches('credentialsForm.submitting')}
        onSubmit={params => send({ type: 'SUBMIT_CREDENTIALS', data: params })}
        error={credentialsContext.credentialsError}
      />
    );
  if (credentialsState.matches('polling'))
    return (
      <VerifyingCredentials
        providerName={credentialsContext.provider.name}
        timeoutMilliseconds={z.coerce.number().parse(delays?.TIME_OUT)}
        complete={credentialsState.matches('polling.verifiedTransitioning')}
      />
    );
  if (
    credentialsState.matches('mfaVerificationMethodsForm') &&
    credentialsContext.returnedVerificationMethods
  )
    return (
      <MfaVerificationMethodForm
        provider={credentialsContext.provider}
        mfaVerificationMethods={credentialsContext.returnedVerificationMethods}
        onSubmit={({ selectedVerificationMethod }) =>
          send({ type: 'SUBMIT_METHOD', data: selectedVerificationMethod })
        }
        onProviderReselect={() => send('RESELECT_PROVIDER')}
        pending={credentialsState.matches('mfaVerificationMethodsForm.submitting')}
        error={credentialsContext.credentialsError}
      />
    );
  if (credentialsState.matches('mfaVerificationCodeForm'))
    return (
      <MfaVerificationCodeForm
        selectedVerificationMethod={credentialsContext.selectedVerificationMethod}
        onSubmit={({ verificationCode }) => send({ type: 'SUBMIT_CODE', data: verificationCode })}
        provider={credentialsContext.provider}
        pending={credentialsState.matches('mfaVerificationCodeForm.submitting')}
        submissionError={credentialsContext.credentialsError}
      />
    );
  if (credentialsState.matches('exit')) return null;
  throw new Error('Unknown state');
};
