import { Input, Alert, Button, Text, FlexCrate, Label } from '@arcadiapower/shrike';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import {
  AcquisitionTemplateOutput,
  AcquisitionTemplateOutputField,
  Provider,
  Organization,
  TemplateFieldsForm,
  TemplateFieldsFormSchema,
} from '@arc-connect/schema';
import { useTranslation } from 'react-i18next';
import { useController, Control, useForm, UseControllerProps } from 'react-hook-form';
import { parseErrorMessage } from '@client/config/errors';
import { ContentSectionFullHeight, Footer } from '@client/components/connect-container';
import { trackEvent } from '@client/utils/analytics';
import { Form } from '../form';
import { Disclaimer } from '../disclaimer';
import { OrganizationTerms } from '../organization-terms';
import { ForgotPasswordLink } from '../forgot-password-link';

const ReactHookFormInput = ({
  attribute,
  required,
  label,
  innerIndex,
  length,
  specialCase,
  attributeCount,
  control,
}: AcquisitionTemplateOutputField & {
  attributeCount: number;
  // We cannot type control because the labels rely on a dynamic number
  control: Control<any>;
  innerIndex?: number;
}) => {
  const { t } = useTranslation('connect', { keyPrefix: 'components.credentialsForm' });
  const name = useMemo(() => {
    // the first value is just the label without the number
    const derivedCount = attributeCount === 1 ? '' : attributeCount;
    return `${attribute}${derivedCount}`;
  }, [attribute, attributeCount]);

  const isInnerField = innerIndex !== undefined;
  const appliedLabel = useMemo(() => {
    let appliedLabel = label || attribute;
    if (isInnerField) {
      appliedLabel = t('partialLabel', { label: appliedLabel, position: innerIndex + 1 });
    }
    return appliedLabel;
  }, [label, attribute, innerIndex, isInnerField, t]);

  const rules: UseControllerProps['rules'] = useMemo(() => {
    const result: UseControllerProps['rules'] = {};
    if (required) {
      result.required = t('validations.fieldRequiredErrorMessage', { fieldName: appliedLabel });
    }
    if (attribute === 'accountNumber') {
      result.deps = 'confirmAccountNumber';
    }
    if (attribute === 'confirmAccountNumber') {
      result.validate = (value, formValues) =>
        value === formValues.accountNumber || t('validations.fieldConfirmationFailsErrorMessage');
    }
    if (specialCase === 'conEdAccountNumber') {
      result.pattern = {
        value: /^\d{11}$/,
        message: t('validations.fieldConEdAccountNumberErrorMessage'),
      };
    }
    if (length) {
      const lengthValidation = {
        value: length,
        message: t('validations.fieldLengthErrorMessage', { fieldName: appliedLabel, length }),
      };
      result.minLength = lengthValidation;
      result.maxLength = lengthValidation;
    }

    return result;
  }, [required, length, appliedLabel, t, attribute, specialCase]);

  const { field, fieldState } = useController({
    name,
    control,
    rules,
  });

  useEffect(() => {
    if (fieldState.isDirty) {
      trackEvent(`credentials-field-modified-${attribute}`, {
        label: appliedLabel,
        backendField: name,
      });
    }
  }, [fieldState.isDirty, attribute, appliedLabel, name]);

  const maxLength = specialCase === 'conEdAccountNumber' ? 11 : length;

  const type = attribute === 'password' ? 'password' : 'text';
  return (
    <Input
      hideLabel={isInnerField}
      label={appliedLabel}
      errorText={fieldState.error?.message}
      maxLength={maxLength}
      type={type}
      onChange={value => {
        field.onChange(value);
      }} // send value to hook form
      onBlur={field.onBlur} // notify when input is touched/blur
      value={field.value} // input value
      name={field.name} // send down the input name
    />
  );
};

export type Props = {
  acquisitionTemplate: AcquisitionTemplateOutput;
  onSubmit: (templateFields: TemplateFieldsForm) => void;
  pending: boolean;
  templateFields?: TemplateFieldsForm;
  error?: unknown;
  organization: Organization;
  provider: Provider;
};

export const CredentialsForm = ({
  acquisitionTemplate,
  templateFields,
  onSubmit,
  pending,
  error,
  organization,
  provider,
}: Props): JSX.Element => {
  const { t } = useTranslation('connect', { keyPrefix: 'components.credentialsForm' });

  const { handleSubmit, control } = useForm<TemplateFieldsForm>({ defaultValues: templateFields });

  const handleFormSubmit = useCallback(
    (fieldValues: unknown) => {
      // We are adding fields at runtime, so we will parse the schema
      // to get rid of the ones we don't need (e.g. account number confirmation)
      const templateFieldValues = TemplateFieldsFormSchema.parse(fieldValues);
      onSubmit(templateFieldValues);
    },
    [onSubmit]
  );

  const fieldPositions = {
    password: 0,
    username: 0,
    accountNumber: 0,
    confirmAccountNumber: 0,
  };

  const description = provider.isThirdPartyPortalSupported
    ? t('descriptionThirdPartyPortal')
    : t('description', { providerName: provider.name });

  const buttonText = templateFields ? t('inputs.resubmitButton') : t('inputs.submitButton');

  return (
    <>
      <ContentSectionFullHeight>
        <FlexCrate flexDirection="column" gap="32px">
          <Text textStyle="heading900" tag="h3" data-testid="providerName">
            {provider.name}
          </Text>
          <Text>{description}</Text>
          <Form onSubmit={handleSubmit(handleFormSubmit)} id="credentialsForm">
            <FlexCrate flexDirection="column" gap="16px">
              {acquisitionTemplate.map((input, outerIndex) => {
                if (Array.isArray(input)) {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={outerIndex}>
                      <Label>{input[0].label}</Label>
                      {/* @ts-expect-error alignItems is missing baseline */}
                      <FlexCrate alignItems="baseline" gap="8px">
                        {input.map((nestedInput, innerIndex) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Fragment key={innerIndex}>
                            <ReactHookFormInput
                              {...nestedInput}
                              attributeCount={++fieldPositions[nestedInput.attribute]}
                              control={control}
                              innerIndex={innerIndex}
                            />
                            {/* Add a dash if this is not the last input */}
                            {innerIndex + 1 !== input.length && (
                              <Text textStyle="heading500">-</Text>
                            )}
                          </Fragment>
                        ))}
                      </FlexCrate>
                    </Fragment>
                  );
                }
                return (
                  <ReactHookFormInput
                    {...input}
                    attributeCount={++fieldPositions[input.attribute]}
                    control={control}
                    // eslint-disable-next-line react/no-array-index-key
                    key={outerIndex}
                  />
                );
              })}
              {!!error && <Alert>{parseErrorMessage(error)}</Alert>}
            </FlexCrate>
          </Form>
          <ForgotPasswordLink provider={provider} />
          <Disclaimer />
        </FlexCrate>
      </ContentSectionFullHeight>
      <Footer>
        <OrganizationTerms organization={organization} margin={{ bottom: '24px' }} />
        <Button type="submit" loading={pending} fullWidth={true} form="credentialsForm">
          {buttonText}
        </Button>
      </Footer>
    </>
  );
};
