import { DEBUG_ANALYTICS } from '@client/config/constants';
import * as Sentry from '@sentry/react';

export enum TrackEvents {
  PROVIDER_SELECTED = 'provider-selected',
  PROVIDER_SEARCHED = 'provider-searched',
  PASSWORD_RESET_LINK_CLICKED = 'password-reset-link-clicked',
  TERMS_OF_SERVICE_CLICKED = 'terms-of-service-clicked',
  PRIVACY_POLICY_CLICKED = 'privacy-policy-clicked',
  PREVIOUS_CLICKED = 'previous-clicked',
  CONNECT_INITIALIZED = 'connect-initialized',
  CONNECT_COMPLETED = 'connect-completed',
  MFA_METHODS_RETURNED = 'mfa-methods-returned',
  MFA_METHOD_SELECTED = 'mfa-method-selected',
}

export const logAnalyticsError = (error: Error): void => {
  // TODO: Report this in sentry?
  // eslint-disable-next-line no-console
  if (DEBUG_ANALYTICS) console.error(error.message);
};
export const logAnalytics = (message: string): void => {
  // eslint-disable-next-line no-console
  if (DEBUG_ANALYTICS) console.log(message);
};

export const trackPage = (): void => {
  try {
    logAnalytics(`Page view: ${window.location}`);
    window.analytics?.page();
  } catch (e) {
    logAnalyticsError(e as Error);
  }
};

export const trackEvent = (eventLabel: string, payload?: Record<string, unknown>): void => {
  try {
    let loggingText = `Tracking event: "${eventLabel}"`;
    if (payload) {
      loggingText += ` with properties ${JSON.stringify(payload)}`;
    }
    logAnalytics(loggingText);
    window.analytics?.track(eventLabel, payload);
    Sentry.addBreadcrumb({ message: eventLabel, level: 'info', data: payload });
  } catch (e) {
    logAnalyticsError(e as Error);
  }
};

/**
 * TODO - figure out how we want to "identify users"
 */
export const identifyUser = (primaryId: string, secondaryId: string): void => {
  try {
    logAnalytics(`Identifying account: ${primaryId}-${secondaryId}`);
    window.analytics?.identify(`${primaryId}-${secondaryId}`);
  } catch (e) {
    logAnalyticsError(e as Error);
  }
};

export const resetTracking = (): void => {
  try {
    logAnalytics(`Resetting tracking`);
    window.analytics?.reset();
  } catch (e) {
    logAnalyticsError(e as Error);
  }
};
