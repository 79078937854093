export const en = {
  connect: {
    standaloneWrapper: {
      errors: {
        generic: 'Something went wrong',
        noOrgId: 'Connect requires an organization identifier in the URL',
      },
    },
    screenTitles: {
      default: 'Connect your utility',
      provider: 'Select your utility',
      connectAccount: 'Enter credentials',
      reconnectAccount: 'Reconnect your account',
      polling: 'Connecting with your utility',
      mfaMethodSelection: 'Select a verification method',
      mfaCodeEntry: 'Enter verification code',
      conclusionSuccess: 'Account connected',
      conclusionReceived: 'Credentials received',
    },
    errors: {
      incorrectCredentials: 'Your credentials are incorrect.',
      genericError: 'Something went wrong. Please try again.',
      duplicateCredentialsError: 'There is already a user with these utility credentials.',
      noProviderError: 'You must select a provider to continue.',
    },
    components: {
      connectContainer: {
        backButton: 'Go back',
      },
      disclaimer: {
        text: 'Arcadia uses industry-standard encryption and never shares your data without your permission. Arcadia’s data platform is a service of Arcadia Power, Inc., which is a service provider independent of, and not affiliated with, your utility. Your utility has not endorsed this service.',
      },
      forgotPasswordLink: {
        description: 'Trouble logging in?',
        linkLabel: 'Retrieve your {{providerName}} credentials',
      },
      mfaVerificationCodeForm: {
        description: {
          call: '{{providerName}} will call you at {{verificationMethod}} to confirm your account.',
          email: '{{providerName}} emailed a verification code to {{verificationMethod}}.',
          text: '{{providerName}} texted a verification code to {{verificationMethod}}.',
          other: '{{providerName}} will contact you to confirm your account.',
        },
        inputs: {
          verificationCode: 'Verification code',
          submitButton: 'Submit',
        },
        validations: {
          fieldRequiredErrorMessage: '{{fieldName}} is required',
        },
        errors: {
          invalidCode: 'Incorrect verification code.',
          server: 'There was a problem. Please try again later or contact us for help.',
        },
      },
      organizationTerms: {
        preTermsText: 'By continuing you agree to {{organizationName}}’s',
        regularJoiner: ', ',
        lastJoiner: ' and ',
        ender: '.',
        termsOfServiceLabel: 'Terms of Service',
        privacyPolicyLabel: 'Privacy Policy',
      },
      providerForm: {
        headerText:
          '{{organizationName}} uses Arc by Arcadia to connect to your utility account and retrieve utility data.',
        tooltipText: 'What data is captured?',
        tooltipContent1:
          'Arc uses industry-standard encryption and never shares your data without your permission.',
        tooltipContent2:
          'Connecting your account gathers the following data: provider account number, service address, service type (electric, gas, etc.), bill amounts, due dates, monthly energy consumption, service dates, electricity rate, and an hourly energy usage profile as reported by your smart meter.',
        searchLabel: 'Search by provider name',
        searchHelpText: 'Search for and select your utility to continue.',
        noProvidersText: 'No results found for "<0>{{providerSearchString}}</0>".',
        refineSearchText: 'Please refine your search to continue.',
        continueButton: 'Continue',
        submissionError: 'Something went wrong searching',
        defaultError: 'Something went wrong. Please try submitting your credentials again',
      },
      credentialsForm: {
        description:
          'Please enter your {{providerName}} login credentials. We always keep your data safe and secure.',
        descriptionThirdPartyPortal:
          'Find your account number on a recent bill or by logging into your ConEd account and navigating to Account & Billing > My Account > Manage My Account.',
        partialLabel: '{{label}} Part {{position}}',
        validations: {
          fieldRequiredErrorMessage: '{{fieldName}} is required',
          fieldLengthErrorMessage: '{{fieldName}} must be {{length}} characters long',
          fieldConfirmationFailsErrorMessage: 'Values must match',
          fieldConEdAccountNumberErrorMessage:
            'Please enter the 11-digit account number that was assigned to your ConEd account as of October, 2023',
        },
        inputs: {
          username: 'Username',
          password: 'Password',
          submitButton: 'Submit',
          resubmitButton: 'Update connection',
        },
      },
      mfaVerificationMethodForm: {
        description:
          '{{providerName}} uses two-factor authentication to secure your account. How would you like to receive your verification code?',
        methodTitle: 'Verification method',
        methodType: {
          text: 'Text {{methodName}}',
          email: 'Email {{methodName}}',
          call: 'Call {{methodName}}',
          other: 'Other {{methodName}}',
        },
        disclaimer: 'Texting and data rates may apply.',
        selectUtility: 'Select another utility',
        unableToAuthenticate: 'Unable to authenticate at this moment?',
        submitButton: 'Send verification code',
        validations: {
          verificationMethodRequired: 'You must select a verification method',
        },
      },
      verifiying: {
        verifiyingText:
          'Establishing a secure connection to {{providerName}}.\nThis could take a while.',
      },
      conclusion: {
        loginSuccess:
          'Your {{providerName}} account has been authorized and your utility data is now connected!',
        loginTimedOut:
          'Arc is checking this credential with {{providerName}}.\nYou’re all set – no further action is required.',
        thirdPartyPortal:
          'In the next 2-3 minutes, you will receive an email from ConEd containing a link. Please click on the link to confirm you are granting access to your utility data.',
      },
    },
  },
};
