import { FlexCrate, ProgressBar } from '@arcadiapower/shrike';
import { useTranslation } from 'react-i18next';
import { ContentSectionFullHeight } from '@client/components/connect-container';
import { VerifyingText } from './verifying-credentials.style';

export type Props = {
  providerName: string;
  complete: boolean;
  timeoutMilliseconds: number;
};

export const VerifyingCredentials = ({
  providerName,
  complete,
  timeoutMilliseconds,
}: Props): JSX.Element => {
  const { t } = useTranslation('connect', { keyPrefix: 'components.verifiying' });
  return (
    <ContentSectionFullHeight centerContent dataAttributes={{ testid: 'verifying-credentials' }}>
      <FlexCrate gap="32px" flexDirection="column" alignItems="center">
        <ProgressBar
          data-testid="progress-bar"
          automatic={true}
          secondsToProgress={timeoutMilliseconds / 1000}
          complete={complete}
        />
        <VerifyingText>{t('verifiyingText', { providerName })}</VerifyingText>
      </FlexCrate>
    </ContentSectionFullHeight>
  );
};
