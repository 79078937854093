import { Crate, CrateProps, Link } from '@arcadiapower/shrike';
import { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Organization } from '@schema/schemas';
import { trackEvent, TrackEvents } from '@client/utils/analytics';
import { TermsText } from './organization-terms.style';

type Contract = { displayName: string; link: string; onClick?: () => void };

export type Props = {
  organization: Organization;
  margin?: CrateProps['margin'];
};

export const OrganizationTerms = ({ organization, margin }: Props): JSX.Element | null => {
  const { t } = useTranslation('connect', { keyPrefix: 'components.organizationTerms' });

  const contracts = useMemo(() => {
    const result: Contract[] = [];
    if (organization.termsOfService) {
      result.push({
        displayName: t('termsOfServiceLabel'),
        link: organization.termsOfService,
        onClick: () => trackEvent(TrackEvents.TERMS_OF_SERVICE_CLICKED),
      });
    }
    if (organization.privacyPolicy) {
      result.push({
        displayName: t('privacyPolicyLabel'),
        link: organization.privacyPolicy,
        onClick: () => trackEvent(TrackEvents.PRIVACY_POLICY_CLICKED),
      });
    }

    return result;
  }, [organization, t]);

  const getJoiner = useCallback(
    (index: number, length: number) => {
      if (index + 1 === length) return;
      if (index + 2 === length) return t('lastJoiner');
      return t('regularJoiner');
    },
    [t]
  );

  if (!contracts.length) return null;
  return (
    <Crate margin={margin}>
      <TermsText>
        {t('preTermsText', { organizationName: organization.name })}{' '}
        {contracts.map(({ displayName, link, onClick }, index) => (
          <Fragment key={displayName}>
            <Link href={link} type="inline" color="secondary" onClick={onClick}>
              {displayName}
            </Link>
            {getJoiner(index, contracts.length)}
          </Fragment>
        ))}
        {t('ender')}
      </TermsText>
    </Crate>
  );
};
