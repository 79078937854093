import { useState } from 'react';
import { Alert, Loading } from '@arcadiapower/shrike';
import { z } from 'zod';
import { trpc } from '@client/utils/trpc';
import { useTranslation } from 'react-i18next';
import { Callbacks, apiModes } from '@schema/schemas';
import { ContentSection } from './components/connect-container';
import { ConnectScreens } from './machines/connect/connect-screens';
import { postToParentCallback } from './config/communicators';

const UpdateParamsSchema = z.object({
  updateToken: z.string(),
});

type UpdateParams = z.infer<typeof UpdateParamsSchema>;

const CreateParamsSchema = z.object({
  providerId: z.string().nullish(),
  correlationId: z.string().nullish(),
  apiMode: apiModes.nullish(),
});

type CreateParams = z.infer<typeof CreateParamsSchema>;

const ParamsSchema = z.union([UpdateParamsSchema, CreateParamsSchema]);

export const StandaloneWrapper = (): JSX.Element => {
  const { t } = useTranslation('connect', { keyPrefix: 'standaloneWrapper' });

  const url = new URL(window.location.toString());
  const path = url.pathname;
  const uniqueId = path.replace('/', '');

  const [callbacks] = useState(() => setUpCallbacks());

  if (!uniqueId) {
    // TODO: do something better here
    return (
      <ContentSection centerContent>
        <Alert>{t('errors.noOrgId')}</Alert>
      </ContentSection>
    );
  }

  const params = Object.fromEntries(url.searchParams);
  const parsedParams = ParamsSchema.parse(params);

  const {
    data: authData,
    isInitialLoading: authIsLoading,
    error: authError,
  } = trpc.auth.verifyUpdateToken.useQuery((parsedParams as UpdateParams).updateToken, {
    enabled: !!(parsedParams as UpdateParams).updateToken,
  });

  const providerId = 'providerId' in parsedParams ? parsedParams.providerId : authData?.providerId;
  const { correlationId, apiMode } = parsedParams as CreateParams;
  const queries = trpc.useQueries(t => [
    t.organization.getByUniqueId(uniqueId),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    t.provider.getById(providerId!, { enabled: !!providerId }),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    t.provider.getAcquisitionTemplate({ providerId: providerId! }, { enabled: !!providerId }),
  ]);

  const [{ data: orgData }, { data: providerData }, { data: acquisitionTemplateData }] = queries;

  if (authIsLoading || queries.some(q => q.isInitialLoading)) {
    return (
      <ContentSection centerContent>
        <Loading />
      </ContentSection>
    );
  }
  if (!orgData || authError || queries.some(q => q.isError)) {
    // TODO: parse error
    return (
      <ContentSection centerContent>
        <Alert>{t('errors.generic')}</Alert>
      </ContentSection>
    );
  }

  return (
    <ConnectScreens
      organization={orgData}
      config={{
        providerDetails:
          providerData && acquisitionTemplateData
            ? {
                provider: providerData,
                acquisitionTemplate: acquisitionTemplateData,
              }
            : undefined,
        correlationId: correlationId || undefined,
        verifiedTokenInfo: authData,
        apiMode: apiMode || 'live',
        callbacks,
      }}
    />
  );
};

// Exported for testing
export const setUpCallbacks = (): Callbacks | undefined => {
  const isIframe = window.top !== window.self;
  if (!isIframe) return undefined;

  return {
    onStart: postToParentCallback('onStart'),
    onEnd: postToParentCallback('onEnd'),
    onCredentialsSubmitted: postToParentCallback('onCredentialsSubmitted'),
  };
};
